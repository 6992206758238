export default [
    {
      path: '/mobile/v2/users',
      meta: { layout: "default", renderNavBar: true, renderSideBar: true },
      name: 'MobileV2Users',
      component: () => import('@/pages/pagesMobileV2/Users/index.vue')
    },
    {
      path: '/mobile/v2/taskStatus',
      meta: { layout: "default", renderNavBar: true, renderSideBar: true },
      name: 'MobileV2TaskStatus',
      component: () => import('@/pages/pagesMobileV2/TaskStatus/index.vue')
    },
    {
      path: '/mobile/v2/task/:id/data',
      meta: {
        layout: "default", renderNavBar: true, renderSideBar: true
      },
      name: 'MobileV2TaskData',
      component: () => import('@/pages/pagesMobileV2/TaskData/index.vue')
    },
    {
      path: '/mobile/v2/task/notify',
      meta: {
        layout: "default", renderNavBar: true, renderSideBar: true
      },
      name: 'MobileV2TaskNotify',
      component: () => import('@/pages/pagesMobileV2/NotifyTask/index.vue')
    },
    {
      path: '/mobile/v2/receipt',
      meta: {
        layout: "default", renderNavBar: true, renderSideBar: true
      },
      name: 'MobileV2Receipts',
      component: () => import('@/pages/pagesMobileV2/Receipts/index.vue')
    },
    {
      path: '/mobile/v2/tasks',
      meta: {
        layout: "default", renderNavBar: true, renderSideBar: true
      },
      name: 'MobileV2Tasks',
      component: () => import('@/pages/pagesMobileV2/Tasks/index.vue')
    },
    {
      path: '/mobile/v2/tasks/create',
      meta: {
        layout: "default", renderNavBar: true, renderSideBar: true
      },
      name: 'MobileV2TasksCreate',
      component: () => import('@/pages/pagesMobileV2/Tasks/create.vue')
    },
    {
      path: '/mobile/v2/cashout',
      meta: {
        layout: "default", renderNavBar: true, renderSideBar: true
      },
      name: 'MobileV2Cashout',
      component: () => import('@/pages/pagesMobileV2/Cashout/index.vue')
    },
    {
      path: '/mobile/v2/deals',
      meta: {
        layout: "default", renderNavBar: true, renderSideBar: true
      },
      name: 'MobileV2Deals',
      component: () => import('@/pages/pagesMobileV2/Deals/index.vue')
    },
    {
      path: '/mobile/v2/userProfile/:userId',
      meta: {
        layout: "default", renderNavBar: true, renderSideBar: true
      },
      name: 'MobileV2UserProfile',
      component: () => import('@/pages/pagesMobileV2/UserProfile/index.vue')
    },
    {
      path: '/mobile/v2/assignTask',
      meta: {
        layout: "default", renderNavBar: true, renderSideBar: true
      },
      name: 'MobileV2AssignTask',
      component: () => import('@/pages/pagesMobileV2/AssignTask/index.vue')
    },
]