<template>
  <v-app-bar color="primary" fixed dark app>
    <v-btn icon @click.stop="handleDrawerToggle" class="ml-1 mr-4">
      <v-icon>menu</v-icon>
    </v-btn>

    <img src="./../assets/images/logo.png" class="logo" v-show="$vuetify.breakpoint.smAndUp" @click="$router.push({ name: 'Root' })">

    <v-spacer></v-spacer>
    
    <v-btn small @click="toggleView()" v-if="isAdmin()" class="mr-2">
      {{ $store.state.loggedInAs == 'VENDOR' ? "View As Admin" : "View As Vendor" }} 
    </v-btn>

    <v-btn icon @click="handleFullScreen()" v-show="$vuetify.breakpoint.smAndUp">
      <v-icon>fullscreen</v-icon>
    </v-btn>

    <v-menu
      offset-y
      origin="center center"
      :nudge-bottom="10"
      transition="scale-tranisition"
    >
      <template v-slot:activator="{ on }">
        <v-avatar size="48" tile color="transparent" v-on="on">
          <v-icon>account_circle</v-icon>
        </v-avatar>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in items"
          :to="!item.href ? { name: item.name } : item.href"
          :href="item.href"
          @click="item.click"
          ripple="ripple"
          rel="noopener"
          :key="index"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          icon: "account_circle",
          name: "Profile",
          title: "Profile",
          click: () => {},
        },
        {
          icon: "settings",
          name: "ChangePassword",
          title: "Change Password",
          click: () => {},
        },
        {
          icon: 'fullscreen_exit',
          href: '#',
          title: 'Logout',
          click: () => {  window.getApp.$emit('APP_LOGOUT') }
        },
      ],
    }
  },
  methods: {
    handleDrawerToggle () {
      window.getApp.$emit('APP_DRAWER_TOGGLED');
    },

    toggleView() {
      let role = (this.$store.state.loggedInAs == "VENDOR") ? "ADMIN" : "VENDOR"

      this.$store.state.loggedInAs = role
      window.$cookies.set("izcRole", role)

      role == "ADMIN" ? this.$router.push({ name: 'Root' }) : this.$router.push({ name: 'VendorDashboard' })
    },

    handleFullScreen() {
      if(this.$store.state.fullScreen === false) {
        this.$store.state.fullScreen = !this.$store.state.fullScreen
        this.openFullScreen()
      } else {
        this.$store.state.fullScreen = !this.$store.state.fullScreen
        this.closeFullScreen()
      }
    },

    openFullScreen() {
      let elem = document.documentElement;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen();
      } else if (elem.msRequestFullScreen) {
        elem.msRequestFullScreen();
      }
    },

    closeFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullScreen) {
        document.webkitExitFullScreen();
      } else if (document.msExitFullScreen) {
        document.msExitFullScreen();
      }
    },

    isAdmin() {
      let localUser = this.$store.state.loggedInAs == "ADMIN" ? this.$store.state.adminUser : this.$store.state.vendorUser
      let roles = localUser && localUser.roles ? localUser.roles : []

      return roles.filter(r => r == "ADMIN").length == 1 ? true : false
    }
  }

};
</script>
